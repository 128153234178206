import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './cssreset.css';
import ChatWidget from './ChatWidget';
import reportWebVitals from './reportWebVitals';
import { v4 as uuidv4 } from 'uuid';
import { getGeoLocation, getDeviceBatteryStatus } from './lib/event-tracker.js';
import axios from 'axios';

function initCreamChatWidget(props = {}) {
  const cont = document.createElement('div')
  cont.id = 'cream-chatwidget';
  document.body.appendChild(cont);

  let externalUserId = uuidv4();
  if (sessionStorage?.getItem('X-CREAM-EXTUSER')) {
    externalUserId = sessionStorage.getItem('X-CREAM-EXTUSER');
  } else {
    sessionStorage.setItem('X-CREAM-EXTUSER', externalUserId);
  }

  if (!props.channel) {
    throw new Error('Channel is not set in the initCreamChatWidget function.');
  }

  const target = {
    externalUserId: externalUserId,
    channel: props.channel,
  };

  navigator.creamEvt = {
    target,
    submit: async (event, payload = {}) => {
      try {
        const geoLocResult = await getGeoLocation();
        const battery = await getDeviceBatteryStatus();
        const device = {
          geoLocation: geoLocResult,
          battery,
          userAgent: navigator.userAgent,
          language: navigator.language,
          languages: navigator.languages,
        };
        const page = {
          origin: window.location.origin,
          host: window.location.host,
          href: window.location.href,
          pathname: window.location.pathname,
          search: window.location.search,
          hash: window.location.hash,
        };
        await axios.post(`${props.apiUrl || 'https://app.creamsolution.io'}/api/v1/ext/channel/webchat/${props.channel}/creamevt`, {
          externalUserId, device, page, event, payload,
        }, {
          responseType: 'json',
          timeout: 60000,
        });
      } catch (ex) {
        console.error('Failed to submit metric.', ex);
      }
    },
  };

  const payload = {
    socketUrl: props.socketUrl || 'https://app.creamsolution.io',
    apiUrl: props.apiUrl || 'https://app.creamsolution.io',
    channel: props.channel,
    metadata: props.metadata || {},
    chatboxTitle: props.chatboxTitle || '智能客服',
    poweredByText: 'Powered by Cream Technology Limited',
    externalUserId: externalUserId,
  };
  const root = ReactDOM.createRoot(document.getElementById('cream-chatwidget'));
  root.render(<ChatWidget initializeProps={payload} />);
  reportWebVitals();
}

navigator.initCreamChatWidget = initCreamChatWidget;

// initCreamChatWidget({
//   socketUrl: 'http://localhost:8083',
//   apiUrl: 'http://localhost:8080',
//   channel: '669e21e9e3b63da84a40002b',
//   metadata: {
//     from: window.origin,
//   },
//   chatboxTitle: 'Cream 智能客服',
// });
